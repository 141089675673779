import React from 'react';
import cloneDeep from 'lodash.clonedeep';

import Editable from './Editable';

import { breakbefore } from './breakbefore';
import { formatTextSmart as formatText } from './formatText';

import './copyright.css';

export function Copyright(props) {
  const paragraphs = props.content.split('\n\n');

  const output = paragraphs.map((paragraph, idx) => {
    return (
      <div
        className={'copyright' + (props.breakbefore ? ' breakbefore' : '')}
        key={idx}
      >
        {formatText(paragraph)}
      </div>
    );
  });

  return <div className="copyright-container">{output}</div>;
}
Copyright.displayName = 'Copyright';

export function EditableCopyright(props) {
  const rendering = <Copyright {...props} />;

  const { description, ...existingData } = cloneDeep(data);

  existingData.content.value = props.content;
  existingData.breakbefore.value = props.breakbefore;

  const actions = {
    moveUp: props.moveUp,
    moveDown: props.moveDown,
    save: props.save,
    delete: props.delete,
    add: props.add,
  };

  return (
    <Editable
      description={description}
      data={existingData}
      {...actions}
      rendering={rendering}
      type="copyright"
      preview={Copyright}
      documentSettings={props.documentSettings}
    />
  );
}

export const data = Object.freeze({
  description: `A copyright notice, generally placed at the end of the order of service.
    It will be positioned at the bottom of the page. This is the appropriate place
    to include CCLI numbers etc. Material that should be italicised should be encosed in
    asterisks *like this*.`,
  content: {
    type: 'text',
    value: '',
    displayTitle: 'Content',
  },
  breakbefore,
});
