import React from 'react';
import cloneDeep from 'lodash.clonedeep';
import ReferenceParser from 'referenceparser';

import { Heading2 } from './Headings';
import { Minister } from './Minister';
import { People } from './People';
import Editable from './Editable';

import { breakbefore } from './breakbefore';
import formatReference from './formatReference';
import bookIntroduction from './bookIntroduction';

const rp = new ReferenceParser();

export function GospelReading(props) {
  const reference = rp.parseComplexReference(props.reference);
  const { book } = reference;

  let readingText;
  if (Boolean(props.text)) {
    readingText = (
      <>
        <Minister content={props.text} />
        <div className="scripturereference">{formatReference(reference)}</div>
      </>
    );
  } else {
    readingText = <Minister content={formatReference(reference)} />;
  }

  return (
    <div
      className={'reading gospel' + (props.breakbefore ? ' breakbefore' : '')}
    >
      <Heading2 content={props.title || 'Gospel reading'} />
      {props.dominusVobiscum ? (
        <>
          <Minister content="The Lord be with you" />
          <People content="and also with you." />
        </>
      ) : (
        false
      )}
      <Minister
        content={`Hear the Gospel of our Lord Jesus Christ according to ${book}.`}
      />
      <People content="Glory to you, O Lord." />
      {readingText}
      <Minister content="This is the Gospel of the Lord." />
      <People content="Praise to you, O Christ." />
    </div>
  );
}
GospelReading.displayName = 'Gospel reading';

export class Reading extends React.Component {
  static displayName = 'Reading';

  render() {
    const reference = rp.parseComplexReference(this.props.reference);

    const { book } = reference;

    let readingText;
    if (Boolean(this.props.text)) {
      readingText = (
        <>
          <Minister content={this.props.text} />
          <div className="scripturereference">{formatReference(reference)}</div>
        </>
      );
    } else {
      readingText = <Minister content={formatReference(reference)} />;
    }

    return (
      <div
        className={'reading' + (this.props.breakbefore ? ' breakbefore' : '')}
      >
        <Heading2 content={this.props.title || 'Reading'} />
        {this.props.omitIntroduction || !this.props.reference ? null : (
          <Minister content={`A reading from ${bookIntroduction(book)}.`} />
        )}
        {readingText}
        {this.props.omitConclusion ? null : (
          <>
            <Minister content="This is the word of the Lord." />
            <People content="Thanks be to God." />
          </>
        )}
      </div>
    );
  }
}

export function EditableReading(props) {
  const rendering = <Reading {...props} />;

  const { description, ...existingData } = cloneDeep(readingData);

  existingData.title.value = props.title;
  existingData.reference.value = props.reference;
  existingData.text.value = props.text;
  existingData.breakbefore.value = props.breakbefore;
  existingData.omitIntroduction.value = props.omitIntroduction;
  existingData.omitConclusion.value = props.omitConclusion;

  const actions = {
    moveUp: props.moveUp,
    moveDown: props.moveDown,
    save: props.save,
    delete: props.delete,
    add: props.add,
  };

  return (
    <Editable
      data={existingData}
      {...actions}
      rendering={rendering}
      type="reading"
      description={description}
      preview={Reading}
      documentSettings={props.documentSettings}
    />
  );
}

export function EditableGospelReading(props) {
  const rendering = <GospelReading {...props} />;

  const { description, ...existingData } = cloneDeep(gospelData);

  existingData.title.value = props.title;
  existingData.dominusVobiscum.value = props.dominusVobiscum;
  existingData.reference.value = props.reference;
  existingData.text.value = props.text;
  existingData.breakbefore.value = props.breakbefore;

  const actions = {
    moveUp: props.moveUp,
    moveDown: props.moveDown,
    save: props.save,
    delete: props.delete,
    add: props.add,
  };

  return (
    <Editable
      data={existingData}
      {...actions}
      rendering={rendering}
      type="gospelReading"
      description={description}
      preview={GospelReading}
      documentSettings={props.documentSettings}
    />
  );
}

export const readingData = Object.freeze({
  description:
    'A reading – except the Gospel reading at the Eucharist. If you provide the text for the reading, the reference will appear below it. Otherwise, it will appear between the opening and closing sentences. These sentences are themselves optional. Within the reading, text can be italicised by placing it between asterisks *like this*.',
  title: {
    type: 'shorttext',
    value: '',
    placeholder: 'Title ("First reading", etc.)',
    displayTitle: 'Title',
  },
  reference: {
    type: 'shorttext',
    value: '',
    placeholder: 'Reference',
    displayTitle: 'Reference',
  },
  text: {
    type: 'text',
    value: '',
    placeholder: 'Text of the reading',
    displayTitle: 'Text',
  },
  omitIntroduction: {
    type: 'boolean',
    value: false,
    displayTitle: 'Omit the introductory sentence',
  },
  omitConclusion: {
    type: 'boolean',
    value: false,
    displayTitle: 'Omit the closing responsory',
  },
  breakbefore,
});
export const gospelData = Object.freeze({
  description:
    'A Gospel reading for the Eucharist. Gospel readings at other services should use the Reading style. If you provide the text for the reading, the reference will appear below it. Otherwise, it will appear between the opening and closing sentences. Within the reading, text can be italicised by placing it between asterisks *like this*.',
  title: {
    type: 'shorttext',
    value: '',
    placeholder: 'Title ("Gospel reading", etc.)',
    displayTitle: 'Title',
  },
  reference: {
    type: 'shorttext',
    value: '',
    placeholder: 'Reference',
    displayTitle: 'Reference',
  },
  text: {
    type: 'text',
    value: '',
    placeholder: 'Text of the reading',
    displayTitle: 'Text',
  },
  dominusVobiscum: {
    type: 'boolean',
    value: false,
    displayTitle: 'Display "The Lord be with you" before the reading',
  },
  breakbefore,
});
