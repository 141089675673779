import React from 'react';

import Modal from './Modal';
import DialogueButton from './DialogueButton';

import './documentSettingsDialogue.css';

export default class DocumentSettingsDialogue extends React.Component {
  settingsDescription = {
    highlightColour: {
      description: 'Colour for rubrics, headings, etc.',
      type: 'colour',
      css: true,
      default: '#8B0000',
      getValue(event) {
        return event.target.value;
      },
    },
    fontFamily: {
      description: 'Font',
      type: 'options',
      options: {
        Serif: ['Crimson Text', 'EB Garamond', 'Gentium Basic', 'Spectral'],
        'Sans-serif': [
          'Alegreya Sans',
          'Cabin',
          'Gill Sans',
          'OpenDyslexic',
          'Quattrocento Sans',
          'Roboto',
        ],
      },
      getValue(event) {
        return event.target.value || this.options[0];
      },
      note(option) {
        if (option === 'Gill Sans') {
          return (
            <div className="optionNote">
              For copyright reasons, Gill Sans will only work if it is installed
              on your local computer. If you do not have it installed, it will
              be rendered as Alegreya Sans instead.
            </div>
          );
        } else {
          return null;
        }
      },
    },
    headingFontFamily: {
      description: 'Heading font',
      type: 'options',
      css: true,
      options: {
        Serif: ['Crimson Text', 'EB Garamond', 'Gentium Basic', 'Spectral'],
        'Sans-serif': [
          'Alegreya Sans',
          'Cabin',
          'Gill Sans',
          'OpenDyslexic',
          'Quattrocento Sans',
          'Roboto',
        ],
      },
      defaultsTo: 'fontFamily',
      getValue(event) {
        return event.target.value || this.options[0];
      },
    },
    fontSize: {
      description: 'Font size',
      type: 'options',
      css: true,
      options: ['12.5pt', '10pt', '12pt', '14pt'],
      getValue(event) {
        return event.target.value || this.options[0];
      },
    },
    theme: {
      description: 'Theme',
      type: 'options',
      css: false,
      options: ['Dix', 'Wesley', 'Gregory'],
      getValue(event) {
        return event.target.value || this.options[0];
      },
    },
    showCover: {
      description: 'Display cover?',
      type: 'boolean',
      getValue(event) {
        return event.target.checked;
      },
    },
    churchName: {
      description: 'Church name',
      type: 'text',
      placeholder: 'Church name (displayed on the front cover)',
      getValue(event) {
        return event.target.value;
      },
    },
    coverColour: {
      description: 'Feature colour for the cover',
      type: 'colour',
      css: true,
      default: '#8B0000',
      getValue(event) {
        return event.target.value;
      },
    },
    serviceTitle: {
      description: 'Service title',
      type: 'text',
      placeholder: 'Service title (e.g. ‘The Parish Eucharist’)',
      getValue(event) {
        return event.target.value;
      },
    },
    serviceSubTitle: {
      description: 'Service subtitle',
      type: 'text',
      placeholder: 'Service subtitle (e.g. ‘in Ordinary Time’)',
      getValue(event) {
        return event.target.value;
      },
    },
    date: {
      description: 'Service date',
      type: 'text',
      placeholder: 'Service date',
      getValue(event) {
        return event.target.value;
      },
    },
    time: {
      description: 'Service time',
      type: 'text',
      placeholder: 'Service time',
      getValue(event) {
        return event.target.value;
      },
    },
    strapline: {
      description: 'Strapline',
      type: 'text',
      placeholder: 'The parish/diocesan strapline, or any other content',
      getValue(event) {
        return event.target.value;
      },
    },
  };

  getChangeHandler = name => {
    return value => {
      this.setState({
        [name]: this.settingsDescription[name].getValue(value),
      });
    };
  };

  save = () => {
    this.props.save(this.state);
    this.setState({});

    this.props.close();
  };

  constructor(props) {
    super(props);

    this.state = Object.entries(this.settingsDescription).reduce(
      (output, [name, setting]) => {
        output[name] = props.documentSettings[name] || setting.default;

        return output;
      },
      {},
    );
  }

  render() {
    const options = Object.entries(this.settingsDescription).reduce(
      (output, [name, setting], idx) => {
        if (setting.type === 'text') {
          output.push(
            <React.Fragment key={idx}>
              <label htmlFor={`option-${name}`} className="optionLabel">
                {setting.description}
              </label>
              <div className="optionDisplay">
                <input
                  id={`option-${name}`}
                  type="text"
                  value={this.state[name] || ''}
                  onChange={this.getChangeHandler(name)}
                  placeholder={setting.placeholder}
                />
              </div>
            </React.Fragment>,
          );
        } else if (setting.type === 'dynamic') {
          output.push(
            <React.Fragment key={idx}>
              <label htmlFor={`option-${name}`} className="optionLabel">
                {setting.description}
              </label>
              <div className="optionDisplay">{setting.display()}</div>
            </React.Fragment>,
          );
        } else if (setting.type === 'boolean') {
          output.push(
            <React.Fragment key={idx}>
              <label htmlFor={`option-${name}`} className="optionLabel">
                {setting.description}
              </label>
              <div className="optionDisplay">
                <input
                  id={`option-${name}`}
                  type="checkbox"
                  checked={this.state[name] || false}
                  onChange={this.getChangeHandler(name)}
                />
              </div>
            </React.Fragment>,
          );
        } else if (setting.type === 'colour') {
          output.push(
            <React.Fragment key={idx}>
              <input
                type="color"
                value={this.state[name]}
                onChange={this.getChangeHandler(name)}
                name={name}
                id={`edit-${name}`}
              />
              <label htmlFor={`edit-${name}`}>
                {setting.description || name}
              </label>
            </React.Fragment>,
          );
        } else if (setting.type === 'options') {
          let options;
          if (Array.isArray(setting.options)) {
            options = setting.options.map((option, idx) => {
              return (
                <option value={option} key={idx}>
                  {option}
                </option>
              );
            });
          } else {
            options = Object.entries(setting.options).reduce(
              (output, [groupName, options], idx) => {
                output.push(
                  <optgroup label={groupName} key={idx}>
                    {options.map((option, idx) => {
                      return (
                        <option value={option} key={idx}>
                          {option}
                        </option>
                      );
                    })}
                  </optgroup>,
                );
                return output;
              },
              [],
            );
          }

          output.push(
            <React.Fragment key={idx}>
              <label htmlFor={`option-${name}`} className="optionLabel">
                {setting.description}
              </label>
              <div className="optionDisplay">
                <select
                  id={`option-${name}`}
                  name={'option-' + name}
                  onChange={this.getChangeHandler(name)}
                  value={this.state[name] || this.state[setting.defaultsTo]}
                >
                  {options}
                </select>
                {setting.note && setting.note(this.state[name])}
              </div>
            </React.Fragment>,
          );
        } else {
          output.push(`${setting.type} not recognised`);
        }

        return output;
      },
      [],
    );

    return (
      <Modal visible={this.props.open} title="Document settings">
        <div className="modal-summary">
          Alter settings that affect the whole document.
        </div>
        <div className="modal-options documentSettings">{options}</div>
        <div className="settingsButtons">
          <DialogueButton onClick={this.props.close}>Cancel</DialogueButton>
          <DialogueButton onClick={this.save}>Save</DialogueButton>
        </div>
      </Modal>
    );
  }
}
