const prophets = [
  'Isaiah',
  'Jeremiah',
  'Ezekiel',
  'Daniel',
  'Hosea',
  'Joel',
  'Amos',
  'Obadiah',
  'Jonah',
  'Micah',
  'Nahum',
  'Habakkuk',
  'Zephaniah',
  'Haggai',
  'Zechariah',
  'Malachi',
];
const gospels = ['Matthew', 'Mark', 'Luke', 'John'];
const paul = [
  'Romans',
  '1 Corinthians',
  '2 Corinthians',
  'Galatians',
  'Philippians',
  '1 Thessalonians',
  '2 Thessalonians',
  'Philemon',
];
const lettersTo = [
  'Ephesians',
  'Colossians',
  '1 Timothy',
  '2 Timothy',
  'Titus',
  'Hebrews',
];
const lettersOf = [
  'James',
  '1 Peter',
  '2 Peter',
  '1 John',
  '2 John',
  '3 John',
  'Jude',
];

export default function bookIntroduction(book) {
  let match;

  if (!book) return '';

  if (book === 'Song of Songs') {
    return 'the Song of Songs';
  }
  if (book === 'Wisdom') {
    return 'the Wisdom of Solomon';
  }
  if (book === 'Acts') {
    return 'the Acts of the Apostles';
  }
  if (book === 'Revelation') {
    return 'the Revelation to John';
  }
  if (book === 'Ecclesiasticus') {
    return 'Ecclesiasticus';
  }

  if (prophets.includes(book)) {
    return `the prophet ${book}`;
  }

  if (gospels.includes(book)) {
    return `the Gospel according to ${book}`;
  }

  if (paul.includes(book)) {
    if ((match = book.match(/^(\d) ([A-Za-z]+)/))) {
      return {
        '1': `Paul’s first letter to the ${match[2]}`,
        '2': `Paul’s second letter to the ${match[2]}`,
      }[match[1]];
    }
    if (book === 'Philemon') {
      return 'Paul’s letter to Philemon';
    }
    return `Paul’s letter to the ${book}`;
  }

  if (lettersTo.includes(book)) {
    if ((match = book.match(/^(\d)/))) {
      return {
        '1': `the first letter to Timothy`,
        '2': `the second letter to Timothy`,
      }[match[1]];
    }
    return `the letter to the ${book}`;
  }

  if (lettersOf.includes(book)) {
    if ((match = book.match(/^(\d)/))) {
      return {
        '1': `the first letter of ${book}`,
        '2': `the second letter of ${book}`,
        '3': `the third letter of ${book}`,
      }[match[1]];
    }
    return `the letter of ${book}`;
  }

  if ((match = book.match(/^(\d) ([A-Za-z]+)/))) {
    return {
      '1': `the First Book of ${match[2]}`,
      '2': `the Second Book of ${match[2]}`,
    }[match[1]];
  }

  return `the Book of ${book}`;
}
