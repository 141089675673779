import React from 'react';
import cloneDeep from 'lodash.clonedeep';

import Editable from './Editable';

import { breakbefore } from './breakbefore';
import { formatTextSmart as formatText } from './formatText';

export class Rubric extends React.Component {
  static displayName = 'Rubric';

  render() {
    const paragraphs = this.props.content.split('\n\n');

    const output = paragraphs.map((paragraph, idx) => {
      return (
        <div
          className={'rubric' + (this.props.breakbefore ? ' breakbefore' : '')}
          key={idx}
        >
          {formatText(paragraph)}
        </div>
      );
    });

    return output;
  }
}

export function EditableRubric(props) {
  const rendering = <Rubric {...props} />;

  const { description, ...existingData } = cloneDeep(data);

  existingData.content.value = props.content;
  existingData.breakbefore.value = props.breakbefore;

  const actions = {
    moveUp: props.moveUp,
    moveDown: props.moveDown,
    save: props.save,
    delete: props.delete,
    add: props.add,
  };

  return (
    <Editable
      description={description}
      data={existingData}
      {...actions}
      rendering={rendering}
      type="rubric"
      preview={Rubric}
      documentSettings={props.documentSettings}
    />
  );
}

export const data = Object.freeze({
  description:
    'An instruction or note for the congregation. Multiple paragraphs can be made by inserting blank lines between them.',
  content: {
    type: 'text',
    value: '',
    displayTitle: 'Content',
  },
  breakbefore,
});
