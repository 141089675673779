export default function formatReference(reference) {
  if (!reference.book) return '';

  let currentChapter;
  return (
    reference.book.replace('Psalms', 'Psalm') +
    ' ' +
    reference.ranges
      .map(range => {
        if (currentChapter === range.startChapter) {
          if (!range.endChapter) {
            return range.startVerse;
          }

          if (range.startChapter === range.endChapter) {
            return range.startVerse + '–' + range.endVerse;
          } else {
            currentChapter = range.endChapter;

            return (
              range.startVerse + '–' + range.endChapter + '.' + range.endVerse
            );
          }
        } else {
          if (!range.endChapter) {
            currentChapter = range.startChapter;

            if (range.startVerse) {
              return range.startChapter + '.' + range.startVerse;
            } else {
              return range.startChapter;
            }
          }

          if (range.startChapter === range.endChapter) {
            currentChapter = range.endChapter;

            return (
              range.startChapter + '.' + range.startVerse + '–' + range.endVerse
            );
          } else {
            currentChapter = range.endChapter;

            return (
              range.startChapter +
              '.' +
              range.startVerse +
              '–' +
              range.endChapter +
              '.' +
              range.endVerse
            );
          }
        }
      })
      .join(', ')
  );
}
