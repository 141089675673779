import React from 'react';
import cloneDeep from 'lodash.clonedeep';

import Modal from './Modal';
import { data, types } from './liturgyComponents';
import { dialogueBox } from './EditDialogue';
import DialogueButton from './DialogueButton';

import './addDialogue.css';

export default class AddDialogue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: null,
    };
  }

  listTypes = () => {
    return Object.keys(data);
  };

  chooseType = type => {
    const newData = cloneDeep(data[type]);

    this.setState({ type, data: newData });
  };

  cancel = () => {
    this.setState({
      type: null,
      data: {},
    });

    this.props.endAdding();
  };

  save = () => {
    const saveData = {};

    for (let [name, data] of Object.entries(this.state.data)) {
      saveData[name] = data.value;
    }

    saveData.type = this.state.type;

    this.props.save(saveData);

    this.props.endAdding();

    this.setState({
      type: null,
      data: {},
    });
  };

  handleChange = event => {
    const data = cloneDeep(this.state.data);

    if (data[event.target.name].type === 'boolean') {
      data[event.target.name].value = event.target.checked;
    } else {
      data[event.target.name].value = event.target.value;
    }

    this.setState({
      data,
    });
  };

  whatTypeDialogue = () => {
    const buttons = Object.entries(types).reduce(
      (accumulator, [type, typeClass], idx) => {
        accumulator.push(
          <DialogueButton key={idx} onClick={() => this.chooseType(type)}>
            {typeClass.displayName}
          </DialogueButton>,
        );

        return accumulator;
      },
      [],
    );

    return (
      <Modal
        visible={this.props.open}
        bgclick={this.props.endAdding}
        title="Add content"
      >
        <div className="addDialogue">
          What do you want to add?
          <div className="addOptions">{buttons}</div>
        </div>
      </Modal>
    );
  };

  typeDialogue = () => {
    let previewData = Object.entries(this.state.data).reduce(
      (acc, [key, value]) => {
        acc[key] = value.value;
        return acc;
      },
      {},
    );

    const documentSettings = this.props.documentSettings;

    let preview = React.createElement(types[this.state.type], {
      documentSettings,
      ...previewData,
    });

    return dialogueBox(
      preview.type.displayName,
      this.state.data,
      this.props.documentSettings,
      this.handleChange,
      this.cancel,
      this.save,
      data[this.state.type].description,
      this.props.open,
      preview,
    );
  };

  render() {
    if (!this.state.type) {
      return this.whatTypeDialogue();
    } else {
      return this.typeDialogue();
    }
  }
}
