import React from 'react';
import uuid from 'uuid/v4';
import arrayMove from 'array-move';
import cloneDeep from 'lodash.clonedeep';

import { editableTypes, types } from './liturgyComponents';
import { AddButton } from './Editable';
import AddDialogue from './AddDialogue';
import Cover from './Cover';

const cssProps = [
  'highlightColour',
  'fontFamily',
  'headingFontFamily',
  'fontSize',
  'coverColour',
];

class Service extends React.Component {
  move = (key, direction) => {
    if (direction === 'up') direction = -1;
    if (direction === 'down') direction = 1;
    direction = Number(direction);

    const index = this.props.data.findIndex(element => element.key === key);

    if (direction < 0 && index === 0) return;

    const data = cloneDeep(this.props.data);

    this.props.updateData(arrayMove(data, index, index + direction));
  };

  delete = key => {
    const index = this.props.data.findIndex(element => element.key === key);

    const data = cloneDeep(this.props.data);
    data.splice(index, 1);

    this.props.updateData(data);
  };

  update = (key, newValues) => {
    const index = this.props.data.findIndex(element => element.key === key);

    const data = cloneDeep(this.props.data);

    const element = data[index];

    for (let [name, value] of Object.entries(newValues)) {
      element[name] = value;
    }

    data[index] = element;

    this.props.updateData(data);
  };

  insertAfter = (key, newValues) => {
    let index;

    if (key) {
      index = this.props.data.findIndex(element => element.key === key);
    } else {
      index = -1;
    }

    const data = cloneDeep(this.props.data);

    if (!newValues.type) {
      // probably a "duplicate" call
      newValues.type = this.props.data[index].type;
    }

    newValues.key = `${newValues.type}-${uuid()}`;
    data.splice(index + 1, 0, newValues);

    this.props.updateData(data);
  };

  startAddAtBeginning = () => {
    this.setState({ addingAtBeginning: true });
  };

  insertAtBeginning = data => {
    this.insertAfter(null, data);
  };

  endAddingAtBeginning = () => {
    this.setState({ addingAtBeginning: false });
  };

  addAtBeginning = () => {
    return (
      <div className="beforeFirst">
        <AddButton
          title="Add new content at the beginning"
          add={this.startAddAtBeginning}
        />
        <AddDialogue
          open={this.state.addingAtBeginning}
          save={this.insertAtBeginning}
          endAdding={this.endAddingAtBeginning}
          documentSettings={this.props.documentSettings}
        />
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      addingAtBeginning: false,
    };
  }

  outputDocumentSettings() {
    const settings = { ...this.props.documentSettings };
    if (settings.fontFamily && settings.fontFamily === 'Gill Sans') {
      settings.fontFamily = "'Gill Sans', 'Gill Sans MT', 'Alegreya Sans'";
    }

    const output =
      'body {' +
      Object.entries(settings).reduce((styles, [name, value]) => {
        if (!value) return styles;
        if (!cssProps.includes(name)) return styles;

        return styles + `--${name}:  ${value};`;
      }, '') +
      '}';

    return <style>{output}</style>;
  }

  render() {
    let output = this.props.data.map((item, idx) => {
      const { type, ...properties } = item;
      if (!types[type]) return null; // an element is in the document that isn't supported

      properties.documentSettings = this.props.documentSettings;

      if (this.props.editable) {
        properties.moveUp = () => {
          this.move(item.key, 'up');
        };
        properties.moveDown = () => {
          this.move(item.key, 'down');
        };
        properties.delete = () => {
          this.delete(item.key);
        };
        properties.save = newValues => {
          this.update(item.key, newValues);
        };
        properties.add = newValues => {
          this.insertAfter(item.key, newValues);
        };
        return React.createElement(editableTypes[type], properties);
      } else {
        return React.createElement(types[type], properties);
      }
    });

    const coverProps = {
      churchName: this.props.documentSettings.churchName,
      serviceTitle: this.props.documentSettings.serviceTitle,
      serviceSubTitle: this.props.documentSettings.serviceSubTitle,
      date: this.props.documentSettings.date,
      time: this.props.documentSettings.time,
      strapline: this.props.documentSettings.strapline,
      showCover: this.props.documentSettings.showCover,
    };

    return (
      <div className="service">
        {this.addAtBeginning()}
        <div
          className="serviceContent"
          data-theme={this.props.documentSettings.theme}
          ref={this.props.serviceRef}
        >
          {this.outputDocumentSettings()}
          <Cover {...coverProps} />
          {output}
        </div>
      </div>
    );
  }
}

export default Service;
