import React from 'react';
import cloneDeep from 'lodash.clonedeep';
import ReferenceParser from 'referenceparser';

import { Heading2 } from './Headings';
import Editable from './Editable';

import { breakbefore } from './breakbefore';
import { formatTextSmart as formatText } from './formatText';
import formatReference from './formatReference';

const rp = new ReferenceParser();

export class ResponsorialPsalm extends React.Component {
  static displayName = 'Responsorial psalm';

  render() {
    const responsorial = Boolean(this.props.response);
    const reference = rp.parseComplexReference(this.props.reference);

    let stanzas;
    if (responsorial) {
      stanzas = this.props.text.split(/\n\s*\n/).map((el, idx) => {
        return (
          <React.Fragment key={idx}>
            <div className="stanza">{formatText(el)}</div>
            <div className="response">{formatText(this.props.response)}</div>
          </React.Fragment>
        );
      });
    } else {
      stanzas = this.props.text.split(/\n\s*\n/).map((el, idx) => {
        return (
          <React.Fragment key={idx}>
            <div className="stanza">{formatText(el)}</div>
          </React.Fragment>
        );
      });
    }

    return (
      <div
        className={
          'responsorialPsalm' + (this.props.breakbefore ? ' breakbefore' : '')
        }
      >
        <Heading2 content="Psalm" />
        {responsorial ? (
          <div className="response">{this.props.response}</div>
        ) : (
          ''
        )}
        {this.props.text ? stanzas : ''}
        <div className="scripturereference">{formatReference(reference)}</div>
      </div>
    );
  }
}

export function EditableResponsorialPsalm(props) {
  const rendering = <ResponsorialPsalm {...props} />;

  const { description, ...existingData } = cloneDeep(data);

  existingData.reference.value = props.reference;
  existingData.response.value = props.response;
  existingData.text.value = props.text;
  existingData.breakbefore.value = props.breakbefore;

  const actions = {
    moveUp: props.moveUp,
    moveDown: props.moveDown,
    save: props.save,
    delete: props.delete,
    add: props.add,
  };

  return (
    <Editable
      description={description}
      data={existingData}
      {...actions}
      rendering={rendering}
      type="rubric"
      preview={ResponsorialPsalm}
      documentSettings={props.documentSettings}
    />
  );
}

export const data = Object.freeze({
  description:
    'A psalm with a congregational response between stanzas. For other types of psalmody, use the Psalm class. Stanzas should be separated by a blank line.',
  reference: {
    type: 'shorttext',
    value: '',
    displayTitle: 'Reference',
  },
  response: {
    type: 'shorttext',
    value: '',
    displayTitle: 'Response',
  },
  text: {
    type: 'text',
    value: '',
    displayTitle: 'Text',
  },
  breakbefore,
});
