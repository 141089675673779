import React from 'react';

import './block.css';

export default function Block(props) {
  if (!props.blocked) return null;

  return (
    <div className="blocking">
      <div className="box">
        <div className="message">{props.children}</div>
      </div>
    </div>
  );
}
