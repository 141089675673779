import React from 'react';
import cloneDeep from 'lodash.clonedeep';

import Editable from './Editable';

import { breakbefore } from './breakbefore';
import { formatTextSmart as formatText } from './formatText';

export class Heading1 extends React.Component {
  static displayName = 'Heading 1';
  render() {
    return (
      <h1 className={this.props.breakbefore ? 'breakbefore' : ''}>
        {formatText(this.props.content)}
      </h1>
    );
  }
}

export class Heading2 extends React.Component {
  static displayName = 'Heading 2';

  render() {
    return (
      <h2 className={this.props.breakbefore ? 'breakbefore' : ''}>
        {formatText(this.props.content)}
      </h2>
    );
  }
}

export function Heading3(props) {
  return (
    <h3 className={props.breakbefore ? 'breakbefore' : ''}>
      {formatText(props.content)}
    </h3>
  );
}

Heading3.displayName = 'Heading 3';

function EditableHeading(level, props) {
  let rendering;
  let type;

  if (level === 1) {
    rendering = <Heading1 {...props} />;
    type = Heading1;
  }
  if (level === 2) {
    rendering = <Heading2 {...props} />;
    type = Heading2;
  }
  if (level === 3) {
    rendering = <Heading3 {...props} />;
    type = Heading3;
  }

  const { description, ...existingData } = cloneDeep(data);

  existingData.content.value = props.content;
  existingData.breakbefore.value = props.breakbefore;

  if (level === 1) {
    delete existingData.breakbefore;
  }

  const actions = {
    moveUp: props.moveUp,
    moveDown: props.moveDown,
    save: props.save,
    delete: props.delete,
    add: props.add,
  };

  return (
    <Editable
      description={description}
      data={existingData}
      {...actions}
      rendering={rendering}
      type={`heading${level}`}
      preview={type}
      documentSettings={props.documentSettings}
    />
  );
}

export function EditableHeading1(props) {
  return EditableHeading(1, props);
}

export function EditableHeading2(props) {
  return EditableHeading(2, props);
}

export function EditableHeading3(props) {
  return EditableHeading(3, props);
}

export const data = {
  description:
    'The three levels of headings represent different levels of importance. The most important headings, such as ‘Liturgy of the Sacrament’ should be the top level. All top-level headings are inserted on a new page.',
  content: {
    type: 'shorttext',
    value: '',
    displayTitle: 'Content',
  },
  breakbefore,
};
