import React from 'react';

import EditDialogue from './EditDialogue';
import AddDialogue from './AddDialogue';

import './editable.css';

export default class Editable extends React.Component {
  editOpen = () => {
    this.setState({
      editing: true,
    });
  };

  editClose = () => {
    this.setState({
      editing: false,
    });
  };

  add = () => {
    this.setState({
      adding: true,
    });
  };

  endAdding = () => {
    this.setState({
      adding: false,
    });
  };

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      adding: false,
    };
  }

  render() {
    const data = { ...this.props.data };
    return (
      <div className="editable" data-type={this.props.type}>
        <EditButton onClick={this.editOpen} />
        <DeleteButton delete={this.props.delete} />
        <MoveButton direction="up" move={this.props.moveUp} />
        <MoveButton direction="down" move={this.props.moveDown} />
        <DuplicateButton add={this.props.add} data={this.props.data} />
        <AddButton add={this.add} />
        {this.props.rendering}
        <EditDialogue
          description={this.props.description}
          open={this.state.editing}
          close={this.editClose}
          data={data}
          save={this.props.save}
          preview={this.props.preview}
          documentSettings={this.props.documentSettings}
        />
        <AddDialogue
          open={this.state.adding}
          close={this.endAdding}
          add={this.props.add}
          endAdding={this.endAdding}
          save={this.props.add}
          documentSettings={this.props.documentSettings}
        />
      </div>
    );
  }
}

function button(type, onClick, title, word) {
  // removed title={title}
  return (
    <div className="button" data-type={type} onClick={onClick} word={word} />
  );
}

class EditButton extends React.Component {
  render() {
    return button('edit', this.props.onClick, 'Edit', 'edit');
  }
}

class DeleteButton extends React.Component {
  render() {
    return button('delete', this.props.delete, 'Delete', 'delete');
  }
}

class MoveButton extends React.Component {
  render() {
    return button(
      'move-' + this.props.direction,
      this.props.move,
      'Move ' + this.props.direction,
      this.props.direction,
    );
  }
}

export function DuplicateButton(props) {
  const duplicate = () => {
    props.add(
      Object.entries(props.data).reduce((acc, [name, value]) => {
        acc[name] = value.value;
        return acc;
      }, {}),
    );
  };

  return button('duplicate', duplicate, 'Duplicate', 'duplicate');
}

export function AddButton(props) {
  return button(
    'add',
    props.add,
    props.title || 'Add new content below',
    'add',
  );
}
