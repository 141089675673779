import React from 'react';
import { formatTextSmart } from './formatText';
import cloneDeep from 'lodash.clonedeep';

import { People } from './People';
import Editable from './Editable';

import { breakbefore } from './breakbefore';

import './minister.css';

export class EditableMinister extends React.Component {
  render() {
    const rendering = <Minister {...this.props} />;

    const { description, ...existingData } = cloneDeep(data);

    existingData.content.value = this.props.content;
    existingData.amen.value = this.props.amen;
    existingData.breakbefore.value = this.props.breakbefore;

    const actions = {
      moveUp: this.props.moveUp,
      moveDown: this.props.moveDown,
      save: this.props.save,
      delete: this.props.delete,
      add: this.props.add,
    };

    return (
      <Editable
        description={description}
        data={existingData}
        type="minister"
        rendering={rendering}
        {...actions}
        preview={Minister}
        documentSettings={this.props.documentSettings}
      />
    );
  }
}

export class Minister extends React.Component {
  static displayName = 'Minister';

  render() {
    const paragraphs = this.props.content.trim().split(/\n\s*\n/);

    const output = paragraphs.map((paragraph, idx) => {
      return (
        <div key={idx} className="minister">
          {formatTextSmart(paragraph.trim())}
        </div>
      );
    });

    const classes = [
      'minister-container',
      ...(this.props.breakbefore ? ['breakbefore'] : []),
      ...(this.props.noIndent ? ['noindent'] : []),
    ];

    return (
      <div className={classes.join(' ')}>
        {output}
        {this.props.amen ? <People content="Amen." /> : ''}
      </div>
    );
  }
}

export const data = Object.freeze({
  description:
    'Any text spoken by one individual, such as the priest. Multiple sections can be added at once by leaving a blank line. Text can be italicised by placing it between asterisks *like this*.',
  content: {
    type: 'text',
    value: '',
    displayTitle: 'Content',
  },
  amen: {
    type: 'boolean',
    value: '',
    displayTitle: 'Add "Amen" at the end',
  },
  noIndent: {
    type: 'boolean',
    value: false,
    displayTitle: 'Don’t indent extra-long lines',
  },
  breakbefore,
});
