import React from 'react';
import Modal from './Modal';

import DialogueButton from './DialogueButton';

import './editDialogue.css';

export default class EditDialogue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: { ...props.data },
    };
  }

  handleChange = event => {
    const data = { ...this.state.data };

    if (data[event.target.name].type === 'boolean') {
      data[event.target.name].value = event.target.checked;
    } else {
      data[event.target.name].value = event.target.value;
    }

    this.setState({
      data,
    });
  };

  cancel = () => {
    this.setState({
      data: this.props.data,
    });

    this.props.close();
  };

  save = () => {
    const saveData = {};

    for (let [name, data] of Object.entries(this.state.data)) {
      saveData[name] = data.value;
    }

    this.props.save(saveData);

    this.props.close();
  };

  render() {
    let preview;
    this.previewClass = !this.previewClass;
    if (this.props.preview) {
      let previewData = Object.entries(this.state.data).reduce(
        (acc, [key, value]) => {
          acc[key] = value.value;
          return acc;
        },
        {},
      );

      const documentSettings = this.props.documentSettings;

      preview = React.createElement(this.props.preview, {
        documentSettings,
        ...previewData,
      });
    }

    return dialogueBox(
      preview.type.displayName,
      this.state.data,
      this.props.documentSettings,
      this.handleChange,
      this.cancel,
      this.save,
      this.props.description,
      this.props.open,
      preview,
      this.previewClass,
    );
  }
}

function getProcessedText(event) {
  let originalText;
  if (
    event &&
    event.clipboardData &&
    event.clipboardData.types &&
    event.clipboardData.getData
  ) {
    const types = event.clipboardData.types;
    if (
      (types instanceof DOMStringList && types.contains('text/html')) ||
      (types.indexOf && types.indexOf('text/html') !== -1)
    ) {
      originalText = event.clipboardData.getData('text/html');
    } else {
      return false;
    }
  } else {
    return false;
  }

  const container = document.createElement('div');
  container.innerHTML = originalText;

  if (!container.querySelector('p')) return false; // we assume p tags after this

  if (container.querySelector('.ve1, .vein')) {
    // Common Worship
    return processCommonWorship(container);
  } else if (container.querySelector('.bibletext')) {
    return processOremus(container);
  } else if (container.querySelector(':scope > p > br')) {
    // probably Hymnary
    return processHasLineBreaks(container);
  } else {
    return false; // follow the default
  }
}

function processOremus(container) {
  return false; // no useful formatting can be done on Oremus texts
}

function processCommonWorship(container) {
  const paras = [];
  let curPara = '';

  let all = false;
  for (let child of container.children) {
    if (child.nodeName !== 'P') continue;
    if (child.classList.contains('veall')) {
      child.removeChild(child.firstElementChild);
      all = true;
    }

    if (
      (!all && child.classList.contains('vein')) ||
      (all && /^\s+/.test(child.innerText))
    ) {
      // indent
      curPara += '   '; // the number of spaces doesn't matter, but 3 looks better
    }

    curPara += child.innerText.trim() + '\n';

    if (
      child.classList.contains('linespace1') ||
      child.classList.contains('linespace2')
    ) {
      paras.push(curPara);
      curPara = '';
    }
  }
  paras.push(curPara);

  return paras.join('\n').trim();
}

function processHasLineBreaks(container) {
  const paras = [];
  let curPara = '';

  for (let child of container.children) {
    if (child.nodeName !== 'P') continue;

    for (let node of child.childNodes) {
      if (node.nodeType === Node.TEXT_NODE) {
        curPara += node.nodeValue.trim();
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.tagName === 'BR') {
          curPara += '\n';
        }
      }
    }

    curPara = curPara.replace(/^\d+\.?\s*/, '');

    paras.push(curPara);
    curPara = '';
  }

  return paras.join('\n').trim();
}

export function dialogueBox(
  title,
  data,
  documentSettings,
  handleChange,
  cancel,
  save,
  summary,
  visible = true,
  preview = null,
  previewClass = null,
) {
  if (!visible) return null;

  const handlePaste = event => {
    event.preventDefault();

    let existingText = event.target.value,
      selectStart = event.target.selectionStart,
      selectEnd = event.target.selectionEnd,
      name = event.target.name;

    const processed = getProcessedText(event);
    if (processed !== false) {
      handleChange({
        target: {
          name,
          value: processed,
        },
      });
      return;
    }

    let originalText = event.clipboardData.getData('text/plain');
    if (
      !/[^\n] *\n *[^\n]/.test(originalText) &&
      !/[^\n]{120,}/.test(originalText)
    ) {
      originalText = originalText.replace(/\n{2}/g, '\n');
    }

    const newText =
      existingText.substring(0, selectStart) +
      originalText +
      existingText.substring(selectEnd, existingText.length);

    handleChange({
      target: {
        name: name,
        value: newText,
      },
    });
  };

  const options = Object.entries(data)
    .filter(data => data[0] !== 'description')
    .map(([name, details], idx) => {
      if (details.type === 'text') {
        return (
          <React.Fragment key={idx}>
            <textarea
              value={data[name].value}
              onChange={handleChange}
              onPaste={handlePaste}
              name={name}
              id={`edit-${name}`}
            />
            <label htmlFor={`edit-${name}`}>
              {details.displayTitle || name}
            </label>
          </React.Fragment>
        );
      } else if (details.type === 'boolean') {
        return (
          <React.Fragment key={idx}>
            <label htmlFor={`edit-${name}`}>
              {details.displayTitle || name}
            </label>
            <input
              type="checkbox"
              checked={data[name].value || false}
              onChange={handleChange}
              name={name}
              id={`edit-${name}`}
            />
          </React.Fragment>
        );
      } else if (details.type === 'shorttext') {
        return (
          <React.Fragment key={idx}>
            <input
              type="text"
              autoComplete="off"
              placeholder={details.placeholder}
              value={data[name].value || ''}
              onChange={handleChange}
              name={name}
              id={`edit-${name}`}
            />
            <label htmlFor={`edit-${name}`}>
              {details.displayTitle || name}
            </label>
          </React.Fragment>
        );
      } else if (details.type === 'dynamic') {
        return (
          <React.Fragment key={idx}>
            {details.render({ value: data[name].value, handleChange })}
            <label htmlFor={`edit-${name}`}>
              {details.displayTitle || name}
            </label>
          </React.Fragment>
        );
      } else {
        return 'not yet implemented';
      }
    });

  let previewBlock;
  if (preview) {
    previewBlock = (
      <div className={'modal-preview ' + (previewClass ? 'flash' : 'flashA')}>
        <div className="preview">{preview}</div>
      </div>
    );
  }

  return (
    <Modal visible={visible} title={title}>
      {previewBlock}
      <div
        className="modal-summary"
        dangerouslySetInnerHTML={{ __html: summary }}
      />
      <div className="modal-options">{options}</div>
      <div className="modal-buttons-name"></div>
      <div className="modal-buttons">
        <DialogueButton onClick={cancel}>Cancel</DialogueButton>
        <DialogueButton onClick={save}>Save</DialogueButton>
      </div>
    </Modal>
  );
}
