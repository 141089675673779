import React from 'react';

import './cover.css';

export default function Cover(props) {
  if (!props.showCover) return false;

  return (
    <div className="cover">
      <div className="churchName">{props.churchName}</div>
      <div className="serviceTitle">{props.serviceTitle}</div>
      <div className="serviceSubTitle">{props.serviceSubTitle}</div>
      <div className="dateTime">
        <div className="date">{props.date}</div>
        <div className="time">{props.time}</div>
      </div>
      <div className="strapline">{props.strapline}</div>
    </div>
  );
}
