import React from 'react';

import './dialogueButton.css';

export default function DialogueButton({ children, onClick }) {
  return (
    <button className="dialogueButton" onClick={onClick}>
      {children}
    </button>
  );
}
