import React from 'react';
import cloneDeep from 'lodash.clonedeep';
import { formatTextSmart } from './formatText';

import { Heading2 } from './Headings';
import Editable from './Editable';
import { Rubric } from './Rubric';

import { breakbefore } from './breakbefore';

import './hymn.css';

export class Hymn extends React.Component {
  static displayName = 'Hymn';

  renderVerses(verses) {
    const individualVerses = verses.split(/\n\s*\n/);

    const renderedVerses = individualVerses.map((verse, idx) => (
      <Stanza content={verse} key={idx} />
    ));

    return <div className="stanzas">{renderedVerses}</div>;
  }

  render() {
    const name = this.props.name;
    const rubric = this.props.rubric ? (
      <Rubric content={this.props.rubric} />
    ) : null;

    return (
      <>
        {this.props.breakbefore ? <div className="breakbefore" /> : null}
        {name ? <Heading2 content={name} /> : null}
        {rubric}
        <div className={'hymn'}>
          {this.renderVerses(this.props.verses)}
          <div className="reference">{this.props.reference}</div>
        </div>
      </>
    );
  }
}

class Stanza extends React.Component {
  render() {
    return <div className="stanza">{formatTextSmart(this.props.content)}</div>;
  }
}

export const data = {
  description:
    'Hymns are formatted in verses. There needs to be an empty line between each verse. The ‘reference’ is any information, such as writer or hymn tune, that you wish to include.',
  name: {
    type: 'shorttext',
    value: '',
    placeholder: 'Hymn',
    displayTitle:
      'Title for the hymn. If left, blank, there will be no heading added.',
  },
  rubric: {
    type: 'shorttext',
    value: '',
    placeholder: 'Any instructions for the hymn',
    displayTitle: 'Rubric',
  },
  verses: {
    type: 'text',
    value: '',
    displayTitle: 'Verses',
  },
  reference: {
    type: 'shorttext',
    value: '',
    placeholder: 'Reference (or hymn name, etc)',
    displayTitle: 'Reference',
  },
  breakbefore,
};

export function EditableHymn(props) {
  const rendering = <Hymn {...props} />;

  const { description, ...existingData } = cloneDeep(data);

  for (let prop in existingData) {
    existingData[prop].value = props[prop];
  }

  const actions = {
    moveUp: props.moveUp,
    moveDown: props.moveDown,
    save: props.save,
    delete: props.delete,
    add: props.add,
  };

  return (
    <Editable
      description={description}
      data={existingData}
      {...actions}
      rendering={rendering}
      type="hymn"
      preview={Hymn}
      documentSettings={props.documentSettings}
    />
  );
}
