import React from 'react';
import WebFont from 'webfontloader';
import uuid from 'uuid/v4';
import cloneDeep from 'lodash.clonedeep';

import CookieConsent from 'react-cookie-consent';

import Service from './Service';
import Tools from './Tools';
import Block from './Block';
import DocumentSettingsDialogue from './DocumentSettingsDialogue';

import render from './rendering';

import './app.css';

export class App extends React.Component {
  constructor(props) {
    super(props);

    let savedData = JSON.parse(
      window.localStorage.getItem('vobiscumSavedData') || '{}',
    );

    if (savedData.data) {
      for (let item of savedData.data) {
        item.key = `${item.type}-${uuid()}`;
      }
    } else {
      fetch('StJohn.json')
        .then(result => result.json())
        .then(result => {
          for (let item of result.data) {
            item.key = `${item.type}-${uuid()}`;
          }

          this.setState(result, this.preview);
        });
    }

    this.iframe = React.createRef();
    this.serviceContent = React.createRef();

    this.state = {
      editable: false,
      edited: false,
      documentSettingsOpen: false,
      documentSettings: {
        fontFamily: 'Crimson Text',
      },
      data: [],
      infoVisible: false,
      ...savedData,
    };
  }

  saveToLocalStorage = () => {
    function extractFromObject(keys, object) {
      return Object.entries(object).reduce((output, [key, value]) => {
        if (keys.includes(key)) output[key] = value;

        return output;
      }, {});
    }

    const dataToSave = extractFromObject(
      ['documentSettings', 'data'],
      cloneDeep(this.state),
    );

    for (let item of dataToSave.data) {
      delete item.key;
    }

    window.localStorage.setItem(
      'vobiscumSavedData',
      JSON.stringify(dataToSave),
    );
  };

  newDocument = () => {
    this.setState({
      data: [],
      documentSettings: {
        fontFamily: 'Crimson Text',
        theme: 'Dix',
      },
    });
    this.preview();
  };

  updateData = data => {
    if (Array.isArray(data)) {
      for (let item of data) {
        item.key = `${item.type}-${uuid()}`;
      }
    }

    this.setState({
      data,
      edited: true,
    });
  };

  getName = () => {
    return [
      this.state.documentSettings.churchName,
      [
        this.state.documentSettings.serviceTitle,
        this.state.documentSettings.serviceSubTitle,
      ]
        .filter(Boolean)
        .join(' '),
      this.state.documentSettings.date,
    ]
      .filter(Boolean)
      .join(' – ');
  };

  preview = () => {
    this.setState(
      {
        editable: false,
        edited: false,
      },
      () => {
        const iframe = this.iframe.current;

        const iframeSrc = render({
          content: this.serviceContent.current.innerHTML,
          name: this.getName(),
          theme: this.state.documentSettings.theme,
        });

        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(iframeSrc);
        iframe.contentWindow.document.close();

        this.loadFonts(iframe);
      },
    );
  };

  print = () => {
    const printWindow = window.open('', 'rendered');
    printWindow.print();
  };

  updateDocumentSettings = newSettings => {
    this.setState({
      documentSettings: newSettings,
      edited: true,
    });
  };

  tools = {
    toggleEditing: () => this.setState({ editable: !this.state.editable }),
    preview: this.preview,
    print: this.print,
    openDocumentSettings: () => this.setState({ documentSettingsOpen: true }),
    save: this.saveToLocalStorage,
    newDocument: this.newDocument,
    toggleInfo: () => this.setState({ infoVisible: !this.state.infoVisible }),
  };

  render() {
    return (
      <div id="app" className="grid-container">
        <div className={'edit' + (this.state.editable ? ' active' : '')}>
          <div className="paneTitle">Edit</div>
          <Service
            updateData={this.updateData}
            data={this.state.data}
            editable={this.state.editable}
            documentSettings={this.state.documentSettings}
            serviceRef={this.serviceContent}
          />
        </div>
        <div className="title">
          jottle <em>beautiful liturgy for everyone</em>
        </div>
        <div className={'preview' + (this.state.edited ? ' edited' : '')}>
          <Block blocked={this.state.edited}>
            <p>The content has changed since the last preview.</p>
            <p>Click “Preview” to view the latest changes.</p>
            <button className="btnPreview" onClick={this.preview}>
              Preview
            </button>
          </Block>
          <div className="paneTitle">Preview</div>
          <iframe
            // eslint-disable-next-line
            src="javascript:;"
            title="rendered"
            name="rendered"
            ref={this.iframe}
            className={this.state.edited ? 'edited' : ''}
          />
        </div>
        <div className="tools">
          <Tools
            infoVisible={this.state.infoVisible}
            edited={this.state.edited}
            {...this.tools}
          />
        </div>
        <DocumentSettingsDialogue
          open={this.state.documentSettingsOpen}
          documentSettings={this.state.documentSettings}
          save={this.updateDocumentSettings}
          close={() => this.setState({ documentSettingsOpen: false })}
        />
        <CookieConsent
          style={{ fontFamily: 'var(--system-font)' }}
          buttonStyle={{ fontFamily: 'var(--system-font)' }}
        >
          Jottle stores some data on your computer to enhance your experience.
        </CookieConsent>
      </div>
    );
  }

  loadFonts(iframe = null) {
    const fontList = [
      this.state.documentSettings.fontFamily,
      this.state.documentSettings.headingFontFamily,
    ];

    const custom = [];

    if (!iframe) {
      fontList.push('Amaranth');
      custom.push('Symbola');
    }

    const notGoogle = ['OpenDyslexic', 'Gill Sans'];

    const google = fontList
      .filter(fontName => fontName && !notGoogle.includes(fontName))
      .map(fontName => fontName + ':400,400i,700,700i');

    if (fontList.includes('OpenDyslexic')) {
      custom.push('OpenDyslexic');
    }

    const settings = {
      classes: false,
      events: false,
    };
    if (google.length) {
      settings.google = {
        families: [...google, '&display=swap'],
      };
    }
    if (custom.length) {
      settings.custom = {
        families: custom,
        urls: ['https://fonts.jottle.io/fonts.css'],
      };
    }
    if (iframe) {
      settings.context = iframe.contentWindow;
    }

    WebFont.load(settings);
  }

  componentDidUpdate() {
    this.loadFonts();
  }

  componentDidMount() {
    this.loadFonts();
    this.preview();
  }
}
