import React from 'react';
import cloneDeep from 'lodash.clonedeep';
import Abc2Svg from 'react-abc2svg';

import Editable from './Editable';

import { breakbefore } from './breakbefore';

import './music.css';

export class EditableMusic extends React.Component {
  render() {
    const rendering = <Music {...this.props} />;

    const { description, ...existingData } = cloneDeep(data);

    existingData.content.value = this.props.content;
    existingData.breakbefore.value = this.props.breakbefore;

    const actions = {
      moveUp: this.props.moveUp,
      moveDown: this.props.moveDown,
      save: this.props.save,
      delete: this.props.delete,
      add: this.props.add,
    };

    return (
      <Editable
        description={description}
        data={existingData}
        type="music"
        rendering={rendering}
        {...actions}
        preview={Music}
        documentSettings={this.props.documentSettings}
      />
    );
  }
}

export class Music extends React.Component {
  static displayName = 'Music';

  render() {
    const frontMatter = `%abc-2.2
%<!--

%%beginsvg
<style type="text/css">
/* latin */

text.sourceFont {font-family: "${this.props.documentSettings.fontFamily}"; font-size: 11pt; font-weight: normal;}
</style>
%%endsvg
%-->

%%vocalfont * * class=sourceFont
%%composerfont * * class=sourceFont
%%tempofont * * class=sourceFont
%%annotationfont * * class=sourceFont

%%fullsvg a
%%pagewidth 12.8cm
%%leftmargin 0cm
%%rightmargin 0cm
%%topmargin 0cm
%%topspace 0cm
%%titlespace 0cm
%%subtitlespace 0cm
%%staffsep 0.7cm
X:1

`;
    return (
      <div className={this.props.breakbefore ? 'breakbefore' : ''}>
        <Abc2Svg abcNotation={frontMatter + this.props.content} />
      </div>
    );
  }
}

export const data = Object.freeze({
  description: `Music, formatted in abc notation. For a reference, see <a href="http://abcnotation.com/wiki/abc:standard:v2.1" target="_blank" rel="noopener noreferrer">the abc notation website</a>. Everything after the <code>X:</code> field can be included.`,
  content: {
    type: 'text',
    value: '',
    displayTitle: 'Content',
  },
  breakbefore,
});
